export const PrivacyPolicy = () => {
  return (
    <div className="text-white font-medium p-4 leading-8">
      <h1>Política de Privacidade</h1>
      <p>
        Sua privacidade é importante para nós. Esta política de privacidade explica como coletamos, usamos e
        protegemos suas informações ao usar nosso jogo e site.
      </p>

      <h2 className="font-bold text-xl mt-4">1. Informações que coletamos</h2>
      <p>
        Coletamos o seu número de telefone para liberar o acesso ao jogo. O número de telefone é essencial para
        identificar e autenticar você como usuário, uma vez que nosso jogo é realizado via WhatsApp e o número de
        telefone serve como sua chave de cliente.
      </p>

      <h2 className="font-bold text-xl mt-4">2. Uso de suas informações</h2>
      <p>As informações que coletamos são utilizadas para os seguintes fins:</p>
      <ul>
        <li>Permitir o acesso e uso do jogo via WhatsApp.</li>
        <li>Garantir uma experiência personalizada e segura.</li>
        <li>Cumprir com requisitos legais, se necessário.</li>
      </ul>

      <h2 className="font-bold text-xl mt-4">3. Ferramentas de análise</h2>
      <p>
        Utilizamos o Google Analytics 4 (GA4) para monitorar e analisar o tráfego do nosso site. Essas informações
        nos ajudam a entender como os usuários interagem com nosso site e melhoram nossos serviços. O GA4 coleta
        informações como endereço IP, tipo de navegador e páginas visitadas, mas essas informações são agregadas e
        não identificam usuários individualmente.
      </p>

      <h2 className="font-bold text-xl mt-4">4. Compartilhamento de informações</h2>
      <p>
        Não compartilhamos o seu número de telefone ou outras informações pessoais com terceiros, exceto quando
        exigido por lei ou para proteger nossos direitos legais.
      </p>

      <h2 className="font-bold text-xl mt-4">5. Segurança</h2>
      <p>
        Estamos comprometidos em proteger suas informações. Utilizamos medidas técnicas e organizacionais para
        garantir que os dados coletados sejam armazenados de forma segura.
      </p>

      <h2 className="font-bold text-xl mt-4">6. Seus direitos</h2>
      <p>
        Você tem o direito de:
        <ul>
          <li>Acessar as informações que coletamos sobre você.</li>
          <li>Solicitar a exclusão de seus dados pessoais.</li>
          <li>Entrar em contato conosco para esclarecer dúvidas sobre esta política.</li>
        </ul>
      </p>

      <h2 className="font-bold text-xl mt-4">7. Contato</h2>
      <p>
        Caso tenha dúvidas ou preocupações sobre nossa política de privacidade, entre em contato através do nosso
        suporte oficial.
      </p>

      <h2 className="font-bold text-xl mt-4">8. Atualizações nesta política</h2>
      <p>
        Esta política de privacidade pode ser atualizada periodicamente. Recomendamos que você revise esta página
        regularmente para se manter informado.
      </p>

      <p>Última atualização: 14/12/2024</p>
    </div>
  );
};
