export const Header = () => {
  const buttonClass = 'w-[150px] h-14 py-4 text-center bg-red-500 skew-x-[-25deg] relative text-white no-underline z-10'
  const buttonWppClass = 'w-[180px] h-14 py-4 text-center bg-green-500 skew-x-[-25deg] relative text-white no-underline z-10'

  return (
    <header className="relative flex justify-center  flex-col min-h-[100vh] w-[100vw] overflow-hidden">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div className="absolute inset-0 bg-[rgba(40,37,54,0.4)] z-10 overflow-hidden"></div>
        <img
          className="absolute inset-0 w-full h-full object-cover z-0 overflow-hidden"
          fetchPriority="high"
          decoding="async"
          src="https://s3.us-east-1.amazonaws.com/static.rpggame.com.br/header-500x281.jpg"
          alt="Mestre RPG em um cenário épico de batalha com IA"
          width="1920"
          height="1080"
          srcSet="https://s3.us-east-1.amazonaws.com/static.rpggame.com.br/header-800x450.jpg 800w, https://s3.us-east-1.amazonaws.com/static.rpggame.com.br/header-1024x576.jpg 1024w, https://s3.us-east-1.amazonaws.com/static.rpggame.com.br/header-1536x864.jpg 1523w"
        />
      </div>
      <div className="lg:w-[60%] ml-4 pr-4 flex flex-col gap-md">
        <h1 className="relative text-2xl lg:text-6xl text-white font-medium text-left z-20">
          RPG - Use o poder da IA como mestre RPG.
        </h1>
        <p className="relative text-lg lg:text-2xl text-white text-left z-20 mt-4">
          Nos conectamos com a API da OpenAI para criar um mestre de RPG que pode te ajudar a criar histórias incríveis diretamente no seu WhatsApp.
        </p>
        <div className="flex gap-4 mt-12 ml-2">
          <a className={buttonClass} href="#planos-rpg">
            <span className="block m-0 uppercase skew-x-[25deg] font-bold">
              Ver planos
            </span>
          </a>
          <a
            className={buttonWppClass}
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/5583987553752?text=Vamos%20come%C3%A7ar%20uma%20aventura!"
          >
            <span className="block m-0 uppercase skew-x-[25deg] font-bold">
              Começar agora
            </span>
          </a>
        </div>
      </div>
    </header>
  )
}