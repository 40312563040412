import Footer from '@components/Footer';
import { Header } from '@components/Header';
import { Plans } from '@components/Plans';

export const Home = () => {
  return (
    <>
      <Header />
      <Plans />
      <Footer />
    </>
  );
};
