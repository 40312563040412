import { useState } from 'react';
import { Card } from './Card';
import { ModalConfirmation } from '../ModalConfirmation';
import { FullScreenLoader } from '../FullScreenLoader';

const API_URL = 'https://api.rpggame.com.br/checkout-session'

export const Plans = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string>();

  const buyProduct = async (phone: string) => {
    setIsOpen(false);
    setIsLoading(true);

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId: selectedPlan, phone: `+55${phone}` }),
      });

      const { checkoutUrl } = await response.json();
      window.location.href = checkoutUrl;
    }
    catch(e) {
      setIsLoading(false);
    }
  }

  const onSelectPlan = (plan: string) => {
    setSelectedPlan(plan);
    setIsOpen(true);
  }

  return (
    <main id="planos-rpg" className="my-8 mx-7">
      <h2 className="font-bold text-lg text-white">
        Adquira já seu passaporte e entre nessa aventura!
      </h2>
      <section className="flex flex-col lg:flex-row gap-6 lg:gap-12 mt-4 justify-center">
        <Card 
          image="https://s3.us-east-1.amazonaws.com/static.rpggame.com.br/card-solo.jpg"
          imgAlt="Plano de Aventura Solo no RPG WhatsApp"
          title="Aventura solo"
          description="Uma aventura solo com uma IA Mestre em RPG diretamente no seu WhatsApp. Basta mandar mensagem para nosso número e se divertir."
          price="R$ 15,00"
          onClick={() => onSelectPlan('prod_RLUSL4jIaBsfds')}
        />
        <Card 
          image="https://s3.us-east-1.amazonaws.com/static.rpggame.com.br/card-group.jpg"
          imgAlt="Plano de Aventura em Grupo no RPG WhatsApp"
          title="Aventura em grupo"
          description="Uma aventura em grupo, com um grupo de até 5 pessoas, com uma IA Mestre em RPG diretamente no seu WhatsApp. Basta mandar mensagem para nosso número e se divertir."
          price="R$ 50,00"
          onClick={() => onSelectPlan('prod_RLUSL4jIaBsfds')}
        />
      </section>
      <ModalConfirmation isOpen={isOpen} onClose={() => setIsOpen(false)} onConfirme={buyProduct} />
      <FullScreenLoader open={isLoading} />
    </main>
  )
};
