interface FullScreenLoaderProps {
  open: boolean;
}

export const FullScreenLoader = ({ open }: FullScreenLoaderProps) => {
  if (!open) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="flex flex-col items-center">
        <svg
          className="animate-spin h-12 w-12 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C6.48 0 2 4.48 2 10h2zm2 5.3l1.1-1.1C7.17 15.52 8 14.35 8 13h2c0 1.5-.67 2.83-1.75 3.75L6 17.3zm10-6.6c0-1.35-.83-2.52-2.1-3.2L14 5.7l1.1-1.1C16.33 5.17 18 6.5 18 8h-2z"
          ></path>
        </svg>
      </div>
    </div>
  );
};