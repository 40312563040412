import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div className="bg-[#2e2b3b] text-center py-2 text-white border-t-2 border-t-green-500">
      <img 
        src="/logo192.png" 
        alt="Logo do site" 
        className="h-10 w-10 object-cover my-0 mx-auto"
      />
      <Link to="/politicas-de-privacidade" className="hover:text-gray-300 block mt-2">
        Políticas de Privacidade
      </Link>
      <p className="text-sm mt-1">&copy; {new Date().getFullYear()} RPG Game. Todos os direitos reservados.</p>
    </div>
  )
};
