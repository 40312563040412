import { useState } from "react";

interface ModalConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirme: (phone: string) => void;
}

export function ModalConfirmation({ isOpen, onClose, onConfirme }: ModalConfirmationProps) {
  const [phone, setPhone] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        {/* Header */}
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h2 className="text-lg font-semibold">Confirmar compra</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-600 focus:outline-none"
            aria-label="Fechar"
          >
            &times;
          </button>
        </div>

        {/* Body */}
        <div className="p-6">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Número de Telefone
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="(XX) XXXXX-XXXX"
            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>

        {/* Footer */}
        <div className="flex justify-end px-6 py-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-2 focus:ring-gray-400"
          >
            Cancelar
          </button>
          <button
            onClick={() => onConfirme(phone)}
            disabled={!phone.trim()}
            className="ml-3 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-gray-400 focus:ring-2 focus:ring-blue-500"
          >
            Confirmar compra
          </button>
        </div>
      </div>
    </div>
  );
}
