interface CardProps {
  image: string;
  imgAlt: string;
  title: string;
  description: string;
  price: string;
  onClick: () => void;
}

export const Card: React.FC<CardProps> = ({ image, imgAlt, title, description, price, onClick }) => {
  return (
    <div
      className="max-w-sm"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick()}
    >
      <div className="bg-white shadow-lg rounded-lg overflow-hidden group hover:shadow-xl transition-shadow duration-300">
        <div className="relative">
          <img 
            src={image}
            alt={imgAlt}
            loading="lazy"
            className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
          />
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-800 group-hover:text-red-500 transition-colors duration-300">
            {title}
          </h3>
          <p className="text-gray-600 mt-2">
            {description}
          </p>
          <div className="mt-4">
            <span className="text-xl font-bold text-gray-900">{price}</span>
          </div>
        </div>
      </div>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "${title}",
            "description": "${imgAlt}",
            "offers": {
              "@type": "Offer",
              "priceCurrency": "BRL",
              "price": "${price.replace('R$ ', '')}",
              "availability": "https://schema.org/InStock"
            }
          }
        `}
      </script>
    </div>
  )
}